.magazine {
  margin: 0 auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 98vh;
  width: 100vw;
  background-color: black;
  border: thick double gray;
  /* background-image:url("https://images.pexels.com/photos/1419923/pexels-photo-1419923.jpeg?auto=compress&cs=tinysrgb&h=900&dpr=1"); */
}

.magazine .page {
  height: 100%;
}

.magazine .page img {
  max-width: 100%;
  height: 100%;
}
